import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Article from '../components/article'
import Hero from '../components/hero'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Index = () => {
  return (
    <>
      <Seo
        pageTitle={'Home'}
        description={
          'Legacy Home Protect Ltd was founded with a view to provide strong foundations of personal service and competitive Home Insurance products.'
        }
      />

      <Layout>
        <main>
          <Hero />
          <Article>
            <h1 className='display-4'>Home</h1>
            <p>
              Legacy Home Protect Ltd was founded with a view to provide strong
              foundations of personal service and competitive Home Insurance
              products.
            </p>
            <p>
              Although the market for insurance has changed with an increasing
              number of comparison websites offering seemingly convenient and
              easy ways to access the cheapest quotes we do believe your needs
              are the same. That is, people need the right product to suit their
              particular needs at the best price possible.
            </p>
            <p>
              What has changed is that although people may still be getting good
              prices the personal service has disappeared overnight. This is a
              situation that has been brought about by the quick and easy online
              quote comparison services. The result is customers are not always
              clear on what cover they have bought, and often find themselves
              either out of pocket due to high excesses or worse still not even
              covered due to unseen exclusions or non-disclosures.
            </p>
            <p>
              The key to us is our belief in the service you receive; delivered
              by a team of professionals. Insurance is too important to solely
              trust to the click of a mouse where payment and commitment is
              quick, but information about the product is slow.
            </p>
            <p>We act for our customers NOT the insurance company.</p>
            <p>
              We believe in treating our clients as individuals with different
              insurance requirements and tailor our cover accordingly arranging
              cover with a reputable underwriter such as Ageas Insurance. We can
              offer cover for your own home, a Tenants Contents policy if you
              rent and also Landlord (Buy to Let) policies if you let a property
              to others. We can also offer Home Emergency cover as either an
              add-on to a policy or as a stand-alone policy.
            </p>
            <p>
              Call us now on 0345 450 3359 to find out how we can help find the
              right insurance policy for you
            </p>
            <h2 className='display-4 mt-5'>Our Mission</h2>
            <div className='row justify-content-center'>
              <div className='col-md-10'>
                <ul className='list-group list-group-flush'>
                  <li className='list-group-item'>
                    To provide services and/or products to our clients based on
                    the principle of "Consumer Saving".
                  </li>
                  <li className='list-group-item'>
                    To provide the highest level of customer satisfaction to all
                    clients and potential clients, throughout their dealings
                    with all areas of the Company.
                  </li>
                  <li className='list-group-item'>
                    To ensure the products we sell will benefit our clients
                    whilst offering sustainable growth and profitability for the
                    Company and therefore a secure future and career path for
                    the Company's employees.
                  </li>
                </ul>
              </div>
            </div>
            <h2 className='display-4 mt-5'>Products</h2>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-xl-4'>
                  <div className='card card-product shadow'>
                    <Link to={'/combined-home-insurance'}>
                      <StaticImage
                        className='card-img-top '
                        src={'../images/combined.png'}
                        alt={'Combined Home Insurance'}
                        placeholder='blurred'
                        Layout={'fullWidth'}
                        formats={['auto', 'webp', 'avif']}
                        quality={100}
                      ></StaticImage>
                    </Link>
                    <div className='card-body'>
                      <h5 className='card-title'>Combined Home Insurance</h5>
                      <p className='card-text'>
                        If you own your home you know the importance of having
                        the right cover for your home and its contents.
                      </p>
                      <Link
                        className='btn btn-primary'
                        to={'/combined-home-insurance'}
                      >
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>

                <div className='col-xl-4'>
                  <div className='card card-product shadow'>
                    <Link to={'/landlords-insurance'}>
                      <StaticImage
                        className='card-img-top '
                        src={'../images/landlords.png'}
                        alt={'Landlords Insurance'}
                        placeholder='blurred'
                        Layout={'fullWidth'}
                        formats={['auto', 'webp', 'avif']}
                        quality={100}
                      ></StaticImage>
                    </Link>
                    <div className='card-body'>
                      <h5 className='card-title'>Landlords Insurance</h5>
                      <p className='card-text'>
                        Whether you’re renting out a second house, or you have a
                        more extensive buy-to-let portfolio, you'll appreciate
                        the importance of ensuring your investment is safely
                        protected
                      </p>
                      <Link
                        className='btn btn-primary'
                        to={'/landlords-insurance'}
                      >
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>

                <div className='col-xl-4'>
                  <div className='card card-product shadow'>
                    <Link to={'/home-emergency-cover'}>
                      <StaticImage
                        className='card-img-top '
                        src={'../images/emergency.png'}
                        alt={'Home Emergency Cover'}
                        placeholder='blurred'
                        Layout={'fullWidth'}
                        formats={['auto', 'webp', 'avif']}
                        quality={100}
                      ></StaticImage>
                    </Link>
                    <div className='card-body'>
                      <h5 className='card-title'>Home Emergency Cover</h5>
                      <p className='card-text'>
                        This reassuring 24-hour home emergency insurance cover
                        can offer valuable peace of mind against things like
                        heating, plumbing and electrical problems.
                      </p>
                      <Link
                        className='btn btn-primary'
                        to={'/home-emergency-cover'}
                      >
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Article>
        </main>
      </Layout>
    </>
  )
}

export default Index
