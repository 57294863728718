import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Hero = () => {
  return (
    <div style={{ display: 'grid' }}>
      <StaticImage
        style={{
          gridArea: '1/1',
          height: '30vh'
        }}
        layout='fullWidth'
        alt=''
        src={'../images/home-hero.jpg'}
        formats={['auto', 'webp', 'avif']}
        placeholder={'blurred'}
        quality={100}
      />
    </div>
  )
}

export default Hero
